import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/pages/login/login.component';
import { DefaultLayoutComponent } from './containers';
import { AdminGuard } from './core/guards/admin.guard';
import { jwtGuard } from './core/guards/jwt-guard.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [jwtGuard],
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'usuarios',
        canActivate: [AdminGuard], // jwtGuard already checked in parent
        loadComponent: () =>
          import('./components/usuarios/usuarios.component').then(
            (m) => m.UsuariosComponent
          ),
        data: {
          title: $localize`Usuarios`,
        },
      },
      {
        path: 'clientes',
        loadComponent: () =>
          import('./components/clientes/clientes.component').then(
            (m) => m.ClientesComponent
          ),
        data: {
          title: $localize`Clientes`,
        },
      },
      {
        path: 'monedas',
        loadComponent: () =>
          import('./components/currency/currency.component').then(
            (m) => m.CurrencyComponent
          ),
        data: {
          title: $localize`Monedas`,
        },
      },
      {
        path: 'tasas',
        loadComponent: () =>
          import('./components/exchange-rate/exchange-rate.component').then(
            (m) => m.ExchangeRateComponent
          ),
        data: {
          title: $localize`Tasas de Cambio`,
        },
      },
      {
        path: 'tasas/mayorista',
        loadComponent: () =>
          import('./components/exchange-rate/exchange-rate.component').then(
            (m) => m.ExchangeRateComponent
          ),
        data: {
          title: $localize`Tasas de Cambio`,
        },
      },
      {
        path: 'operaciones',
        loadComponent: () =>
          import('./components/operations/operations.component').then(
            (m) => m.OperationsComponent
          ),
        data: {
          title: $localize`Operaciones`,
        },
      },
      {
        path: 'caja',
        loadComponent: () =>
          import('./components/operations/operations.component').then(
            (m) => m.OperationsComponent
          ),
        data: {
          title: $localize`Caja`,
        },
      },
      {
        path: 'caja/reporte',
        loadComponent: () =>
          import(
            './components/operations/components/expense-report/expense-report.component'
          ).then((m) => m.ExpenseReportComponent),
        data: {
          title: $localize`Reporte de Gastos`,
        },
      },
      {
        path: 'caja-ingresos',
        canActivate: [AdminGuard],
        loadComponent: () =>
          import('./components/operations/operations.component').then(
            (m) => m.OperationsComponent
          ),
        data: {
          title: $localize`Ingresos de Caja`,
        },
      },
      {
        path: 'caja-retiros',
        canActivate: [AdminGuard],
        loadComponent: () =>
          import('./components/operations/operations.component').then(
            (m) => m.OperationsComponent
          ),
        data: {
          title: $localize`Retiros de Caja`,
        },
      },
      {
        path: 'categorias',
        loadComponent: () =>
          import(
            './components/operations/components/category-crud/category-crud.component'
          ).then((m) => m.CategoryCrudComponent),
        data: {
          title: $localize`Caja`,
        },
      },
      {
        path: 'tenencias',
        canActivate: [AdminGuard],
        loadComponent: () =>
          import('./components/accounting/accounting.component').then(
            (m) => m.AccountingComponent
          ),
        data: {
          title: $localize`Tenencias`,
        },
      },
      {
        path: 'perfil',
        loadComponent: () =>
          import('./components/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      // relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
