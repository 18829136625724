import { jwtDecode } from 'jwt-decode';
import { JWTDecoded } from '../../components/auth/interfaces/jwt-decoded.interface'
import { CanActivateFn } from '@angular/router';

export const AdminGuard: CanActivateFn = () => {
  const user = localStorage.getItem('user');
  if (user === null) return false;
  const decodedJWT = jwtDecode<JWTDecoded>(JSON.parse(user).accessToken);
  return decodedJWT.role == 'admin';
};
