import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  throwError,
  BehaviorSubject,
  switchMap,
  filter,
  take,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../components/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshingInProgress: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const savedUser = localStorage.getItem('user');
    if (savedUser && !req.url.includes('refresh') && !req.url.includes('flagcdn')) {
      const user = JSON.parse(savedUser);
      req = this.addTokenHeader(req, user.accessToken);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.refreshTokenSubject.next(null);

      const savedUser = localStorage.getItem('user');
      if (savedUser) {
        const user = JSON.parse(savedUser);
        localStorage.removeItem('user');
        return this.authService.refreshToken(user.refreshToken).pipe(
          switchMap((token: any) => {
            this.refreshingInProgress = false;
            this.refreshTokenSubject.next(token.accessToken);
            localStorage.setItem('user', JSON.stringify(token));
            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.refreshingInProgress = false;
            this.removeUserAndNavigate();
            return throwError(() => err);
          })
        );
      } else {
        this.removeUserAndNavigate();
        return throwError(() => new Error('User not found'));
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addTokenHeader(request, token));
        })
      );
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private removeUserAndNavigate() {
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
}
