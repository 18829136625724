import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/components/auth/auth.service';

export const jwtGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const user = localStorage.getItem('user');

  if (!user) {
    return of(router.parseUrl('/login'));
  }

  let parsedUser;
  try {
    parsedUser = JSON.parse(user);
  } catch (e) {
    return of(router.parseUrl('/login'));
  }

  const { accessToken, refreshToken } = parsedUser;

  if (!accessToken || isTokenExpired(accessToken)) {
    if (!refreshToken) {
      return of(router.parseUrl('/login'));
    }

    return authService.refreshToken(refreshToken).pipe(
      map((res) => {
        if (res.accessToken) {
          localStorage.setItem('user', JSON.stringify(res));
          return true;
        } else {
          return router.parseUrl('/login');
        }
      }),
      catchError((error) => {
        console.error('Token refresh failed', error);
        return of(router.parseUrl('/login'));
      })
    );
  }

  return of(true);
};

function isTokenExpired(token: string): boolean {
  const decoded = decodeToken(token);
  if (decoded && decoded.exp) {
    return decoded.exp < Date.now() / 1000;
  }
  return true;
}

function decodeToken(token: string): any {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    return null;
  }
}
