import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { navItems } from './_nav';
import { decodeJWT } from '../../core/utils/decodeJwt';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SidebarService, SidebarComponent } from '@coreui/angular'; // Import SidebarComponent

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  @ViewChild('sidebar') sidebar!: SidebarComponent;

  role = decodeJWT()?.role || '';
  isNarrow: boolean = false;
  public navItems = navItems.filter((nav) => nav.roles?.includes(this.role));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 1280px)'])
      .subscribe((result) => {
        this.isNarrow = result.matches;
      });
  }

  onNavItemClick() {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 1280) {
      this.sidebarService.toggle({ sidebar: this.sidebar, narrow: true });
    }
  }
}
