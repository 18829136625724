import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  AvatarModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  FormModule,
  GridModule,
  NavModule,
  ProgressModule,
  SpinnerModule,
  TableModule,
  TabsModule,
} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';

import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    NavModule,
    TabsModule,
    ProgressModule,
    ReactiveFormsModule,
    ButtonModule,
    GridModule,
    IconModule,
    FormModule,
    TableModule,
    ButtonGroupModule,
    AvatarModule,
    RouterModule,
    SpinnerModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private authService: AuthService, private router: Router) {
    const user = localStorage.getItem('user');
    if (user) {
      this.router.navigate(['/dashboard']);
    }
  }

  isSubmitting: boolean = false;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  isInvalid(formControlName: string) {
    return (
      this.loginForm?.get(formControlName)?.invalid &&
      this.loginForm?.get(formControlName)?.touched
    );
  }
  submit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.isSubmitting = true;

    const loginPayload = {
      email: this.loginForm.value.email as string,
      password: this.loginForm.value.password as string,
    };

    this.authService.login(loginPayload).subscribe({
      next: (res) => {
        if (res.accessToken) {
          const tokenPayload = JSON.parse(atob(res.accessToken.split('.')[1]));
          if (
            tokenPayload.role === 'admin' ||
            tokenPayload.role == 'user' ||
            tokenPayload.role == 'counter-user'
          ) {
            localStorage.setItem('user', JSON.stringify(res));
            this.router.navigate(['/dashboard']);
          } else {
            this.loginForm.setErrors({ unauthorized: true });
          }
        } else {
          this.loginForm.setErrors({ unauthorized: true });
          this.loginForm.get('password').setErrors({
            invalidCredentials: true,
          });
          this.loginForm?.get('password')?.setErrors({ invalid: true });
          this.loginForm.markAllAsTouched();
          this.loginForm.markAsDirty();
        }
      },
      error: (error) => {
        this.loginForm.setErrors({
          invalidCredentials: true,
        });
        this.loginForm?.get('password')?.setErrors({ invalid: true });
        this.loginForm.markAllAsTouched();
        this.loginForm.markAsDirty();
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      },
    });
  }
}
