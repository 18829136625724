import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginRequest } from './interfaces/login-request.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(payload: LoginRequest): Observable<any> {
    return this.http.post(`${environment.api_url}/auth/login`, payload);
  }

  refreshToken(refreshToken: string): Observable<any> {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${refreshToken}`
    );
    return this.http.get(`${environment.api_url}/auth/refresh`, {
      headers: headers,
    });
  }
}
