import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { decodeJWT } from 'src/app/core/utils/decodeJwt';
import { ClassToggleService, HeaderComponent } from '@coreui/angular';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {
  @Input() sidebarId: string = 'sidebar';
  name: string;

  constructor(
    private classToggler: ClassToggleService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    let decoded = decodeJWT();
    if (decoded) {
      this.name = decoded.firstName[0] + decoded.lastName[0];
    }
  }

  logOut() {
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
}
