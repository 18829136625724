import { INavData } from '@coreui/angular';

interface IExtendedNavData extends INavData {
  roles?: string[];
}

export const navItems: IExtendedNavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    title: true,
    name: 'Gestión de Personal',
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    name: 'Usuarios',
    url: '/usuarios',
    iconComponent: { name: 'cil-user' },
    roles: ['admin'],
  },
  {
    name: 'Clientes',
    url: '/clientes',
    iconComponent: { name: 'cil-group' },
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    title: true,
    name: 'Gestión de Divisas',
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    name: 'Monedas',
    url: '/monedas',
    iconComponent: { name: 'cil-dollar' },
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    name: 'Tasas de Cambio',
    iconComponent: { name: 'cil-calculator' },
    roles: ['admin', 'user', 'counter-user'],
    children: [
      {
        name: 'Mostrador',
        url: '/tasas',
      },
      {
        name: 'Mayorista',
        url: '/tasas/mayorista',
      },
    ],
  },
  {
    title: true,
    name: 'Operaciones',
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    name: 'Cambio',
    url: '/operaciones',
    iconComponent: { name: 'cil-money' },
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    name: 'Gastos de Caja',
    url: '/caja',
    iconComponent: { name: 'cil-exposure' },
    roles: ['admin', 'user', 'counter-user'],
  },
  {
    name: 'Ingresos de Caja',
    url: '/caja-ingresos',
    iconComponent: { name: 'cil-arrow-top' },
    roles: ['admin'],
  },
  {
    name: 'Retiros de Caja',
    url: '/caja-retiros',
    iconComponent: { name: 'cil-arrow-bottom' },
    roles: ['admin'],
  },
  {
    name: 'Categorías',
    url: '/categorias',
    iconComponent: { name: 'cil-list' },
    roles: ['admin'],
  },
  {
    title: true,
    name: 'Posición',
    roles: ['admin'],
  },
  {
    name: 'Tenencias',
    url: '/tenencias',
    iconComponent: { name: 'cil-book' },
    roles: ['admin'],
  },
];
