<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm [formGroup]="loginForm" (ngSubmit)="submit()">
                <h1>Ingreso - Staff</h1>
                <p class="text-medium-emphasis">Ingresá a tu cuenta</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input
                    autoComplete="email"
                    formControlName="email"
                    cFormControl
                    placeholder="Correo electrónico"
                    [ngClass]="{ 'is-invalid': isInvalid('email') }"
                  />
                  <div id="email-feedback" class="invalid-feedback">
                    Debe ingresar un mail válido.
                  </div>
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    placeholder="Contraseña"
                    type="password"
                    formControlName="password"
                    [ngClass]="{ 'is-invalid': isInvalid('password') }"
                  />
                  <div
                    id="password-feedback"
                    *ngIf="loginForm.get('password')?.errors?.['required']"
                    class="invalid-feedback"
                  >
                    Debe ingresar una contraseña.
                  </div>
                  <div
                    id="password-feedback2"
                    *ngIf="loginForm.hasError('invalidCredentials')"
                    class="invalid-feedback"
                  >
                    La contraseña o el email ingresados son incorrectos.
                  </div>
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button
                      cButton
                      class="px-4 loginBtn"
                      type="submit"
                      color="teal-100"
                      [disabled]="loginForm.invalid"
                    >
                      <span *ngIf="!isSubmitting">Ingresar</span>
                      <span *ngIf="isSubmitting"
                        ><c-spinner size="sm"></c-spinner
                      ></span>
                    </button>
                  </c-col>
                  <c-col xs="6s">
                    <!-- <button cButton class="px-0" color="link">
                      ¿Olvidaste tu contraseña?
                    </button> -->
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
          <c-card [ngStyle]="{ 'width.%': 44 }" class="text-white py-5">
            <c-card-body class="text-center">
              <div>
                <img
                  cImg
                  height="250"
                  src="../../../../../assets/img/brand/logo-ai.png"
                />
              </div>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
